body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f7f7f7;
}

.MuiTypography-h3 {
  font-family: 'Georgia', serif;
  color: #000;
}

/* .MuiCard-root {
  transition: transform 0.2s;
}

.MuiCard-root:hover {
  transform: scale(1.05);
} */
